<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="invite-category-modal"
  >
    <div class="invite-category">
      <h2 class="invite-category__title">Invite Contacts</h2>
      <TagifyContacts
          placeholder="Invite via Glocal"
          :options="options"
          @searchChange="fetchAllUsers"
          @fetchSelection="fetchedSelection"
          workingOn="connections"
          :isSubmitted="isSubmit"
          :id="randomId"
          :removeFields="emptyTagifyInput"
        ></TagifyContacts>
      <br>
      <tagify-email
      placeholder="Invite via Email"
      @fetchEmail="fetchedEmail"
      :isSubmitted="isSubmit"
      :nullify="emptyTagifyInput"
      ></tagify-email>
      <p v-if="FieldErr" class="error">Enter atleast one field</p>
      <a-button
        :loading="loader"
        @click="inviteCategory()"
        class="invite-category__btn"
        >Submit</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import TagifyContacts from "./TagifyContacts.vue";
import TagifyEmail from "./TagifyEmail.vue";
export default {
  props: {
    visible: Boolean,
    categoryOptions: Array,
    categoryId: String,
    selectedTopic: Object,
    interestGroupId: String,
    options: Array
  },
  components: {
    TagifyContacts,
    TagifyEmail,
  },
  computed:{
    isSubmit(){
      return this.isSubmitted
    },
    isAuthenticatedUser() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    contacts() {
      const value = this.$store.getters["profile/getFriends"];
      value.map((val) => {
        if (val.profile) {
          let userList = {
            label: val.full_name,
            value: val.id,
            key: val.email,
            icon: val.profile_picture,
          };
          this.friends.push(userList);
          return userList;
        }
      });
      return this.friends;
    },
    randomId() {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      return timestamp;
    }
  },
  emits: ["handle-close", "show-success-modal"],
  watch: {
    emails(value) {
      if (value) {
        this.FieldErr = false;
      }
    },
    selectedUsers(value) {
      if (value) {
        this.FieldErr = false;
      }
    },
    visible(val) { 
      if (val) { 
       this.emptyTagifyInput = false
      }
    }
  },
  data() {
    return {
      contactOptions: [],
      selectedUsers: [],
      onUsersRequest: false,
      emails: [],
      loader: false,
      topicFieldErr: false,
      FieldErr: false,
      isSubmitted:false,
      friends: [],
      emptyTagifyInput: false,
    };
  },
  methods: {
    closeModal() {
      this.emails = [];
      this.selectedUsers = [];
      this.$emit("handle-close", false);
      this.FieldErr = false;
      this.emptyTagifyInput=true
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchAllUsers(value) {
      console.log("HELLLO")
      try {
        console.log(value, "FETCH ALL USERS")
        this.contactOptions = [];
        this.debounce(async () => {
          const payload = {
            full_name: value,
          };
          const response = await this.$store.dispatch(
            "profile/getUsersList",
            payload
          );
          if (response.status == 200) {
            response.data.map(async (val) => {
              if (val.profile) {
                let userList = {
                  label: val.profile.full_name,
                  value: val.id,
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                this.contactOptions.push(userList);
                await this.$store.commit(
                  "profile/setContacts",
                  this.contactOptions
                );
                return userList;
              }
            });
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    async inviteCategory() {
      if (this.emails == "" && this.selectedUsers.length===0) {
        this.FieldErr = true;
        return true;
      }
      try {
        this.loader = true;
        const invitationList = this.selectedUsers.map(val => ({
          to_user: parseInt(val.value),
          permission: 'admin',
          link: `?topic=${(this.selectedTopic.interest).replace(" ","-")}&id=${this.selectedTopic.id}`,
          email:null
        }));
        invitationList.push(...this.emails.map(email => { 
          return {
            email,
            to_user:null,
            permission: 'admin',
            link: `?topic=${(this.selectedTopic.interest).replace(" ","-")}&id=${this.selectedTopic.id}`
          }
        }))
        const payload = {
          interest_group_id: this.interestGroupId,
          list: invitationList,
          emails: this.emails,
        };
        const response = await this.$store.dispatch(
          "article/inviteOnTopic",
          payload
        );
        if (response.status === 201) {
          this.selectedUsers = [];
          this.emails = [];
          this.topicFieldErr = false;
          this.FieldErr = false;
          this.$emit("show-success-modal");
        }
        this.isSubmitted=await true
        this.isSubmitted=await false
        this.closeModal()
        this.loader = false;
      } catch (err) {
        this.loader =false;
        console.log(err);
      }
    },
    fetchedSelection(val){
      this.selectedUsers=val
    },
    fetchedEmail(val){
      this.emails=val
    }
  },
};
</script>
<style lang="scss">
.invite-category-modal {
  .ant-modal {
    width: 50rem !important;
    top: 18rem;
  }
  .ant-modal-content {
    padding: 2.5rem;
    box-shadow: 0px 0px 94px #00000047;
    background: #f6f8ff;
    border-radius: 0;
    .ant-modal-close {
      margin: 3rem;
      .ant-modal-close-x {
        width: fit-content;
        height: fit-content;
        line-height: 0;
      }
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      .invite-category {
        &__title {
          font-size: 2.4rem;
          font-family: $font-secondary-bold;
          line-height: normal;
          margin-bottom: 3rem;
        }
        .one-option-select {
          font-size: 1.5rem;
          padding: 1.5rem;
          height: 4.45rem;
        }
        .error {
          color: red;
          font-size: 1.5rem;
          font-family: $font-secondary;
          margin: 0.5rem 0 1rem;
        }
        &__btn {
          font-size: 1.5rem;
          font-family: $font-secondary-bold;
          width: 10rem;
          margin-top: 4rem;
          margin-left: 78%;
          height: 3.5rem;
          border: none;
          background-color: $color-primary;
          color: $color-white;
          padding: 0;
          span {
            line-height: 1rem;
          }
        }
      }
    }
  }
}
</style>
