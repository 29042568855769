<template>
  <a-drawer
    :placement="drawerPlacement"
    :visible="visibleDrawer"
    class="user-permissons-drawer"
    :class="dynamicClass"
    width="46.5rem"
    title=" People with access"
    @close="closePermissionsdrawer()"
  >
    <div class="user-permissons">
      <div class="user-permissons__body">
        <div class="user-permissons__body--container">
          <div class="user-permissons__body--container--section">
            <div class="user-permissons__body--container--section--header">
              <h2 class="title">Admins</h2>
              <button :class="{ 'disable-btn' : addAdminLoader}" class="btn" @click="addAdmin()">
                <i class="icon icon-add_plus"></i>Add Admin
              </button>
            </div>
            <div class="user-permissons__body--container--section--input">
              <TagifyContacts
                placeholder="Search users"
                :options="contactOptions"
                @searchChange="fetchAllUsers"
                @fetchSelection="fetchedSelection"
                workingOn="connections"
                :isSubmitted="isSubmit"
                :id="randomId"
                :removeFields="emptyTagifyInput"
              ></TagifyContacts>
              <p v-if="adminError" class="error" >{{ adminError }}</p>
            </div>
            <div
              class="user-permissons__body--container--section--active-admin"
            >
              <div class="header">
                <h2>Active Admins <span v-if="usersWithAccessCounts.admin_users">{{ usersWithAccessCounts.admin_users.length }}</span> </h2>
              </div>
              <div class="body">
                <span v-for="user in usersWithAccess" :key="user">
                  <user-card
                     v-if="user.isAdmin"
                    :fullName="user.name"
                    :profile="user.profilePicture"
                    :adminId="user.id"
                    :isOwner="user.isOwner"
                    :createdBy="user.created_by"
                    userType="admin"
                    @removeUser="removeUser"
                  ></user-card>
                </span>
              </div>
            </div>
          </div>
          <div class="user-permissons__body--container--section">
            <div class="user-permissons__body--container--section--header">
              <h2 class="title">Editors</h2>
              <button :class="{ 'disable-btn' : addEditorLoader }" class="btn" @click="addEditor()">
                <i class="icon icon-add_plus"></i>Add Editors
              </button>
            </div>
            <div class="user-permissons__body--container--section--input">
              <TagifyContacts
                placeholder="Search users"
                :options="contactOptions"
                @searchChange="fetchAllUsers"
                @fetchSelection="fetchedSelection"
                workingOn="connections"
                :isSubmitted="isSubmit"
                :id="randomId"
                :removeFields="emptyTagifyInput"
            ></TagifyContacts>
            <p v-if="editorError" class="error" >{{ editorError }}</p>
            </div>
            <div
              class="user-permissons__body--container--section--active-admin"
            >
              <div class="header">
                <h2>Active Editors <span v-if="usersWithAccessCounts.editors">{{ usersWithAccessCounts.editors.length }}</span></h2>
              </div>
              <div class="body">
                <span v-for="user in usersWithAccess" :key="user">
                  <user-card
                     v-if="user.isEditor"
                    :fullName="user.name"
                    :profile="user.profilePicture"
                    :adminId="user.id"
                    userType="editor"
                    @removeUser="removeUser"
                  ></user-card>
                </span>
              </div>
            </div>
          </div>
          <div class="user-permissons__body--container--section">
            <div class="user-permissons__body--container--section--header">
              <h2 class="title">Viewers</h2>
              <button :class="{ 'disable-btn' : addViewerLoader }" class="btn" @click="addViewer()">
                <i class="icon icon-add_plus"></i>Add Viewer
              </button>
            </div>
            <div class="user-permissons__body--container--section--input">
                <TagifyContacts
                  placeholder="Search users"
                  :options="contactOptions"
                  @searchChange="fetchAllUsers"
                  @fetchSelection="fetchedSelection"
                  workingOn="connections"
                  :isSubmitted="isSubmit"
                  :id="randomId"
                  :removeFields="emptyTagifyInput"
                ></TagifyContacts>
                <p v-if="viewverError" class="error" >{{ viewverError }}</p>
            </div>
            <div
              class="user-permissons__body--container--section--active-admin"
            >
              <div class="header">
                <h2>Active Viewers <span v-if="usersWithAccessCounts.students" >{{ usersWithAccessCounts.students.length }}</span></h2>
              </div>
              <div class="body">
                <span v-for="user in usersWithAccess" :key="user">
                  <user-card
                     v-if="user.isStudent"
                    :fullName="user.name"
                    :profile="user.profilePicture"
                    :adminId="user.id"
                    userType="viewer"
                    @removeUser="removeUser"
                  ></user-card>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import UserCard from "@/components/BaseComponents/TopicManagement/UserCard.vue";
import TagifyContacts from "@/components/PdbComponents/TagifyAjax.vue";
export default {
  props: {
    visibleDrawer: Boolean,
    drawerPlacement: String,
  },
  components: {
    TagifyContacts,
    UserCard
  },
  watch: {
    drawerPlacement(val) {
      if (val === "bottom") {
        this.dynamicClass = "user-permissons-drawer-mobile-view";
      } else {
        this.dynamicClass = "";
      }
    },
  },
  data() {
    return {
      dynamicClass: "",
      contactOptions: [],
      selectedConnections: [],
      isSubmit: false,
      emptyTagifyInput: false,
      users: [],
      adminError: "",
      editorError: "",
      viewverError: "",
      addAdminLoader: false,
      addEditorLoader: false,
      addViewerLoader: false,
    };
  },
  computed : { 
    usersWithAccess() { 
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties  
      this.users = this.$store.getters["folders/getUsersWithFolderAccess"];
      return this.$store.getters["folders/getUsersWithFolderAccess"];
    },
    usersWithAccessCounts() {
      return this.$store.getters["folders/getUsersWithFolderAccessCounts"]
    },
  },
  methods: {
    closePermissionsdrawer() {
      this.$emit("close-drawer");
    },
    async fetchAllUsers(value) {
      try {
        this.contactOptions = [];
        this.onUsersRequest = true;
        this.debounce(async () => {
          const payload = {
            full_name: value,
          };
          const response = await this.$store.dispatch(
            "profile/getUsersList",
            payload
          );
          if (response.status == 200) {
            response.data.map(async (val) => {
              if (val.profile) {
                let userList = {
                  label: val.profile.full_name,
                  value: val.id,
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                this.contactOptions.push(userList);
                await this.$store.commit(
                  "profile/setContacts",
                  this.contactOptions
                );
                return userList;
              }
            });
            this.onUsersRequest = false;
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    randomId() {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      return timestamp;
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    fetchedSelection(val) {
      this.selectedConnections = val;
    },
    async addAdmin() { 
      try {
        this.addAdminLoader = true;
        this.adminError = "";
        this.emptyTagifyInput = false;
        const newSelectedUsers = this.selectedConnections.map(user => { 
          return {
            id: user.id,
            name: user.value,
            profilePicture: user.icon,
            isAdmin : true
          }
        })
        const payload = {
          folderId: this.$route.params.id,
          admins: newSelectedUsers.map((val) => {
            return val.id
          }),
        }
        const response = await this.$store.dispatch('folders/addNewUsersInFolder', payload);
        if (response.status === 200) {
          this.usersWithAccessCounts.admin_users.length += newSelectedUsers.length;
          this.users = [...this.users, ...newSelectedUsers]
          this.$store.commit('folders/ADD_USERS', this.users);
          this.selectedConnections = [];
          this.emptyTagifyInput = true
          this.isSubmit = false;
          this.isSubmit = true;
        }
      } catch(err){
        console.error(err);
        if (err.response.status === 400) {
          this.adminError = err.response.data.error
        }
      } finally {
        this.addAdminLoader = false;
      }
      
    },
    async addEditor() { 
      try {
        this.addEditorLoader = true;
        this.editorError = "";
        this.emptyTagifyInput = false;
        const newSelectedUsers = this.selectedConnections.map(user => { 
          return {
            id: user.id,
            name: user.value,
            profilePicture: user.icon,
            isEditor : true
          }
        })
        const payload = {
          folderId: this.$route.params.id,
          editors: newSelectedUsers.map((val) => {
            return val.id
          }),
        }
        const response = await this.$store.dispatch('folders/addNewUsersInFolder', payload);
        if (response.status === 200) {
          this.usersWithAccessCounts.editors.length += newSelectedUsers.length;
          this.users = [...this.users, ...newSelectedUsers]
          this.$store.commit('folders/ADD_USERS', this.users );
          this.selectedConnections = [];
          this.emptyTagifyInput = true;
          this.isSubmit = false;
          this.isSubmit = true;
        }
      } catch(err){
        console.error(err);
        if (err.response.status === 400) {
          this.editorError = err.response.data.error
        }
      } finally {
         this.addEditorLoader = false;
      }
    },
    async addViewer() { 
      try {
        this.addViewerLoader = true;
        this.viewverError = "";
        this.emptyTagifyInput = false;
        const newSelectedUsers = this.selectedConnections.map(user => { 
          return {
            id: user.id,
            name: user.value,
            profilePicture: user.icon,
            isStudent : true
          }
        })
        
        const payload = {
          folderId: this.$route.params.id,
          students: newSelectedUsers.map((val) => {
            return val.id
          }),
        }
        const response = await this.$store.dispatch('folders/addNewUsersInFolder', payload);
        if (response.status === 200) {
          this.usersWithAccessCounts.students.length += newSelectedUsers.length;
          this.users = [...this.users, ...newSelectedUsers]
          this.$store.commit('folders/ADD_USERS', this.users );
          this.selectedConnections = [];
          this.emptyTagifyInput = true;
          this.isSubmit = false;
          this.isSubmit = true;
        }
      } catch(err){
        if (err.response.status === 400) {
          this.viewverError = err.response.data.error
        }
      } finally {
         this.selectedConnections = [];
      }
    },
    async removeUser({ user, userType }) { 
      let foundUser = null
      const payload = {
        folderId: this.$route.params.id,
        userId: user.user_id,
        role: userType === 'viewer' ? 'student' : userType
      }
      await this.$store.dispatch("folders/removeUserFromFolder", payload);
      switch (userType) { 
        case 'admin':
          foundUser = this.users.findIndex((removedUser) => { if(removedUser.id === user.user_id && removedUser.isAdmin === true) return removedUser }) 
          this.users = [...this.users.filter((removedUser, index) => { if (index !== foundUser) return removedUser })]
          this.usersWithAccessCounts.admin_users.length -= 1;
          break;
        case 'editor':
          foundUser = this.users.findIndex((removedUser) => { if(removedUser.id === user.user_id && removedUser.isEditor === true) return removedUser })
          this.users = [...this.users.filter((removedUser, index) => { if (index !== foundUser) return removedUser })]
          this.usersWithAccessCounts.editors.length -= 1;
          break;
        case 'viewer':
          foundUser = this.users.findIndex((removedUser) => { if(removedUser.id === user.user_id && removedUser.isStudent === true) return removedUser })
          this.users = [...this.users.filter((removedUser, index) => { if (index !== foundUser) return removedUser })]
          this.usersWithAccessCounts.students.length -= 1;
          break;
      }
      this.$store.commit('folders/ADD_USERS', this.users );
      this.emitter.emit('user-card-loader',false)
    },
  },
};
</script>

<style lang="scss">
.user-permissons-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;

      .ant-drawer-wrapper-body {
        overflow: hidden;

        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;

          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }

          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }

        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;

          .user-permissons {
            position: relative;
            height: 100vh;

            &__body {
              height: 100%;
              &--container {
                padding: 0 2.5rem;
                position: relative;
                overflow-y: auto;
                height: calc(100vh - 8rem);
                &--section {
                  padding-bottom: 2rem;
                  margin-bottom: 3rem;
                  border-bottom: 1px solid #cdcdcd;
                  &:nth-last-child(2) {
                    border: none;
                    margin-bottom: 0;
                  }
                  &--header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.6rem;
                    .title {
                      font-size: 1.6rem;
                      font-family: $font-primary-medium;
                      line-height: 2.2rem;
                      margin-bottom: 0;
                      color: $color-black;
                    }
                    .btn {
                      width: 11rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 1.2rem;
                      line-height: 1.4rem;
                      color: $color-white;
                      background-color: $color-primary;
                      font-family: $font-primary;
                      outline: none;
                      border: none;
                      border-radius: 5px !important;
                      height: 3rem;
                      margin-left: 1.4rem;
                      cursor: pointer;
                      .icon {
                        margin-right: 0.7rem;
                        font-size: 1rem;
                        color: $color-white;
                      }
                    }
                    .disable-btn {
                      pointer-events: none;
                      background-color: gray;
                      opacity: 0.5;
                    }
                  }
                  &--input {
                    input {
                      height: 4.6rem;
                      width: 100%;
                      padding: 1.4rem;
                      outline: none;
                      border-radius: 7px;
                      background-color: $color-white;
                      font-family: $font-primary;
                      font-size: 1.4rem;
                      color: $color-black;
                      border: 1px solid #00000033;
                    }
                    .error {
                      font-size: 1.4rem;
                      color: red;
                      line-height: normal;
                      margin: 0.8rem 0 0;
                    }
                  }
                  &--active-admin {
                    margin-top: 2.6rem;
                    .header {
                      margin-bottom: 1.8rem;
                      h2 {
                        font-size: 1.4rem;
                        font-family: $font-primary;
                        line-height: 2.2rem;
                        margin-bottom: 1.8rem;
                        color: $color-black;
                      }
                    }
                    .body {
                      display: flex;
                      align-items: center;
                      overflow-x: auto;
                      padding-bottom: 1.5rem;
                      &::-webkit-scrollbar-track {
                        background-color: #ffffff3f;
                        height: 2px;
                      }
                      &::-webkit-scrollbar {
                        width: 3px;
                        background-color: rgb(224, 224, 224);
                        border-radius: 3rem;
                        height: 4px;
                      }
                      &::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 3rem;
                      }
                    }
                  }
                }
                &--footer {
                  position: sticky;
                  bottom: 0;
                  padding: 1.5rem 0;
                  background-color: $color-white;
                  width: 100%;
                  &--btn {
                    border: none;
                    outline: none;
                    background: $color-primary;
                    font-size: 1.6rem;
                    color: $color-white;
                    width: 15rem;
                    height: 3.9rem;
                    font-family: $font-primary-medium;
                    border-radius: 100px !important;
                    display: block;
                    cursor: pointer;
                    margin-left: auto;
                    padding: 0 !important;
                  }
                }
                &::-webkit-scrollbar-track {
                  background-color: #ffffff3f;
                }
                &::-webkit-scrollbar {
                  width: 4px;
                  background-color: rgb(224, 224, 224);
                  border-radius: 3rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 3rem;
                }
              }

              .skeleton-loader {
                padding: 0 2.4rem;

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 2rem;
                        margin: 1rem 0;

                        &:first-child {
                          margin-top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.user-permissons-drawer-mobile-view {
  max-height: 77vh !important;

  .ant-drawer-content-wrapper {
    height: 100% !important;

    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-bottom: 3rem;

          .ant-drawer-title {
            font-size: 3.1rem;
          }
        }

        .ant-drawer-body {
          .user-permissons {
            position: relative;
            height: 100vh;

            &__body {
              &--container {
                height: calc(77vh - 10rem);
                &--section {
                  &--header {
                    margin-bottom: 2.5rem;
                    .title {
                      font-size: 2.3rem;
                      line-height: 2.8rem;
                    }
                    .btn {
                      font-size: 1.8rem;
                      width: 17rem;
                      height: 5rem;
                      .icon {
                        font-size: 1.5rem;
                      }
                    }
                  }
                  &--input {
                    input {
                      height: 6.2rem;
                      padding: 1.4rem 2rem;
                      font-size: 1.8rem;
                    }
                  }
                  &--active-admin {
                    .header {
                      margin-bottom: 1.8rem;
                      h2 {
                        font-size: 2.3rem;
                      }
                    }
                  }
                }
                &--footer {
                  bottom: 0;
                  &--btn {
                    width: 100%;
                    height: 6.8rem;
                    font-size: 2.1rem;
                  }
                }
              }

              .skeleton-loader {
                padding: 0 2.4rem;

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 2rem;
                        margin: 1rem 0;

                        &:first-child {
                          margin-top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>