<template>
  <a-modal :visible="isVisible" :title="count" footer="" class="user-list-modal" @cancel="handleCancel()">
    <div class="user-list-container">
      <template v-if="!isLoading">
        <div v-for="(user, index) in reactedUsers" :key="index" class="user-list-container__user-list-card">
          <div class="image">
            <img :src="user.profilePicture">
          </div>
          <h2>{{ user.userName }}</h2>
          <div class="reaction">
            <img class="image" :src="getUserReactionIcon(user.reaction)" alt="">
          </div>
        </div>
      </template>
      <div v-else>
        <a-skeleton
          v-for="(reactedUsersSkeleton, index) in 5"
          :key="index"
          active
          :avatar="{ shape: 'square' }"
          :title="{ width: '90%' }"
          :paragraph="null"
          class="user-list-container__user-list-skeleton"
        />
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    showModal: {
      default: false,
      type: Boolean
    },
    modalTitle: {
      default: "All",
      type: String
    },
    reactedUsers: {
      default: () => [],
      type: Array
    },
    articleId: String,
    isLoading: {
      default: false,
      type: Boolean
    },
    allReaction: Object,
  },
  computed: {
    isVisible() {
      return this.showModal
    },
    count() {
      return `All  ${this.reactedUsers.length}`;
    }
  },
  data() {
    return {
    showReactionUsers: false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancelModal', true)
    },
    getUserReactionIcon(reactionName) {
      const reaction = this.allReaction.find(
        (reaction) => reaction.name === reactionName
      );

      if (reaction) {
        return reaction.icon;
      }
      return '';
    },
  }
}
</script>
<style lang="scss">
.user-list-modal {
  width: 50rem !important;
  top: 8rem !important;

  .ant-modal-content {
    .ant-modal-header {
      padding: 2rem 3rem;
      border-bottom: 1px solid rgba(135, 149, 157, 0.3);
      margin-bottom: 0;
      background-color: transparent;
      .ant-modal-title {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-family: $font-primary-bold;
      }
    }
    .ant-modal-close {
      margin: 2rem 2.5rem 0 0 !important;
      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        .anticon {
          color: $color-black;
        }
      }
    }

    .ant-modal-body {
      padding: 0 !important;      
      .user-list-container {
        padding: 1rem 3rem;
        overflow-y: auto;
        min-height: 30rem;
        max-height: calc(100vh - 25rem);
        &::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
        }

        &__user-list-card {
          display: flex;
          align-items: center;
          margin: 0.5rem 0;
          padding: 1rem 0;
          width: 100%;
          height: max-content;
          &:first-child {
            margin: 0;
          }

          .image {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 100%;
              background-color: rgb(190, 190, 190);
            }
          }

          h2 {
            font-size: 1.6rem;
            font-family: $font-secondary;
            margin-bottom: 0;
          }

          .reaction {
            width: 2rem;
            height: 2rem;
            margin-left: auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 100%;
            }
          }
        }
        &__user-list-skeleton {
          margin: 2rem 0;
          display: flex;
          align-items: center;
          .ant-skeleton-header {
            height: 3rem;
            width: 3rem;
            border-radius: 100%;
            padding: 0;
            .ant-skeleton-avatar {
              height: 3rem;
              width: 3rem;
              border-radius: 100%;
            }
          }
          .ant-skeleton-content {
            padding-left: 1.5rem;
            height: 1.8rem;
            .ant-skeleton-title {
              margin: 0;
              height: 1.8rem;
            }
          }
        }
      }
      
    }
  }
}
</style>