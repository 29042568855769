<template>
  <div class="student-select-dropdown">
    <button
      @click="toggleDropdown"
      class="student-select-dropdown__dropdown-toggle"
    >
      <div class="student-select-dropdown__dropdown-toggle--selected-student">
        <img
          v-if="selectedStudent"
          :src="selectedStudent.profile_picture"
          class="selected-avatar"
        />
        <div v-else class="students-group-icon">
          <img src="@/assets/icons/students-group-icon.png" alt="students-group-icon" />
        </div>

        <p class="name">
          {{ selectedStudent ? selectedStudent.full_name : "All Contributors" }}
        </p>
      </div>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{
          'student-select-dropdown__dropdown-toggle--arrow-down': !isOpen,
          'student-select-dropdown__dropdown-toggle--arrow-up': isOpen,
        }"
        class="student-select-dropdown__dropdown-toggle--icon"
      >
        <path
          d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
          fill="#0133DD"
        />
      </svg>
    </button>
    <div v-if="isOpen" class="student-select-dropdown__dropdown-menu">
      <form class="student-select-dropdown__dropdown-menu--search-bar">
        <i class="icon icon-search" @click.stop=""></i>
        <input
          class="search-input"
          type="text"
          placeholder="Search students"
          v-model="searchQuery"
        />
      </form>
      <ul class="student-select-dropdown__dropdown-menu--list">
        <li 
        class="list-item"
         @click="selectStudent()"
        >
          <img src="@/assets/icons/students-group-icon.png" class="student-avatar" />
          <p class="name">All Contributors</p>
        </li>
        <li
          v-for="student in filteredStudents"
          :key="student.id"
          class="list-item"
          @click="selectStudent(student)"
        >
          <img :src="student.profile_picture || student.profilePicture" class="student-avatar" />
          <p class="name">{{ student.full_name || student.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props:{
    group:{
      type: String,
      required: true,
      default:'private'
    },
    showFolderUsers:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      isOpen: false,
      searchQuery: "",
      selectedStudent: null,
      students: [
        {
          id: 1,
          name: "Madison Booth",
          avatar:
            "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 2,
          name: "Jamie Oliver",
          avatar:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 3,
          name: "Chris Martin",
          avatar:
            "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 4,
          name: "Jane Doe",
          avatar:
            "https://images.unsplash.com/photo-1504257432389-52343af06ae3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      privateTopicDiscussionUsers:'discussion/privateTopicDiscussionUsers',
      publicTopicDiscussionUsers:'discussion/publicTopicDiscussionUsers'
    }),
    filteredStudents() {      
      let users = []
      if(!this.showFolderUsers){
        users = this.group === 'public' ? [...this.publicTopicDiscussionUsers] : [...this.privateTopicDiscussionUsers]
      }
      else{
        users = this.$store.getters["folders/getUsersWithFolderAccess"];
      }
      return users.filter(student => {
        if(!this.showFolderUsers)
          return student.full_name.toLowerCase().includes(this.searchQuery.toLowerCase()) 
        else
          return (student.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        })
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectStudent(student=null) {
      this.isOpen = false;
      this.searchQuery = "";
      if(student){
        this.$emit('filterStudentDiscussion',student)
      }
      else{
        this.$emit('filterStudentDiscussion',null)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.student-select-dropdown {
  position: relative;
  display: inline-block;
  width: 23.8rem;
  @media (max-width: 390px) {
    width: 14rem;
  }

  &__dropdown-toggle {
    background-color: #e9e9e9;
    border: none;
    padding: 0.7rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 0.6rem !important;

    &--selected-student {
      display: flex;
      align-items: center;

      .selected-avatar {
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 10px;
      }
      .students-group-icon {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 100%;
        background-color: #bababa;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.7rem;
        img {
          width: 1.6rem;
        }
      }
      .name {
        font-size: 1.2rem;
        font-family: $font-primary;
        color: $color-black;
        line-height: 1.6rem;
        margin-bottom: 0;
        max-width: 14rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 390px) {
          max-width: 7rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &--icon {
      width: 1.1rem;
      margin-right: 0.5rem;
      margin-top: 0.2rem;
    }
    &--arrow-down {
      transform: rotate(0);
    }
    &--arrow-up {
      transform: rotate(180deg);
      margin-bottom: 0.2rem;
    }
  }

  &__dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $color-white;
    border-top: none;
    box-shadow: 0px 4px 19.6px 0px #00000040;
    z-index: 1;
    border-radius: 0.8rem;
    display: block;
    width: 100%;
    @media (max-width: 390px) {
      width: 24rem;
    }

    &--search-bar {
      width: calc(100% - 2.2rem);
      margin: 1.4rem auto;
      display: flex;
      align-items: center;
      background-color: #e9e9e9;
      padding: 0 1.2rem;
      border-radius: 100px;
      .icon {
        font-size: 1.3rem;
        opacity: 1;
        color: $color-primary;
      }
      .search-input {
        font-size: 1.2rem;
        font-family: $font-primary;
        line-height: normal;
        padding: 1.2rem 0.8rem;
        line-height: 1.4rem;
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
        &::placeholder {
          color: $color-black;
          opacity: 0.5;
        }
      }
    }

    &--list {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 19rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 0.8rem;
        width: 0.4rem;
        height: 0.4rem;
      }

      .list-item {
        display: flex;
        align-items: center;
        padding: 1rem 1.1rem;
        cursor: pointer;
        border-top: 1px solid $color-dark-grey-5;
        background-color: $color-white;
        margin-right: 0;
        &:hover {
          background-color: #f1f1f1;
        }

        .student-avatar {
          object-fit: cover;
          border-radius: 100%;
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-color: #e9e9e9;
        }
        .name {
          font-size: 1.2rem;
          font-family: $font-primary;
          color: $color-black;
          line-height: 1.6rem;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
