<template>
  <div class="user-card">
    <img
      :src="profile"
      alt="User Image"
      class="user-card__icon"
    />
    <h2 class="user-card__name">{{ fullName }}</h2>
    <p v-if="isPending" class="user-card__pending-btn" >Pending</p>
    <p v-if="isOwner" class="user-card__pending-btn" >Owner</p>
    <a-button :loading="loader" v-else-if="!isPending && createdBy !== adminId" @click="removeUser(adminId)" class="user-card__btn">Remove</a-button>   
  </div>
</template>

<script>
export default {
  props: {
    fullName: String,
    profile: String,
    createdBy: Number,
    adminId: Number,
    isOwner: Boolean,
    editorId: Number,
    groupId: String,
    isPending: Boolean,
    userType:String,
  },
  data() {
    return {
      loader: false
    }
  },
  mounted() { 
    this.emitter.on('user-card-loader', (event) => { this.loader = event})
  },
  methods: {
    removeUser(id) {
      this.loader = true;
       const payload = {
          id: this.groupId,
          user_id: id,
          kick: true
        }
      this.$emit('removeUser', { 'user': payload, 'userType': this.userType })

      // try {
      //   this.loader = true;
      //   const payload = {
      //     id: this.groupId,
      //     user_id: id,
      //     kick: true
      //   }
      //   const response = this.$store.dispatch('article/removeAdminFromTopic', payload);
      //   if (response.status === 200) {
      //     console.log(response);
      //     this.loader = false;
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
    }
  }
}
</script>

<style lang="scss">
.user-card {
  background-color: $color-white;
  padding: 1.3rem 1.2rem;
  border: 0.5px solid #00000033;
  box-shadow: 0px 0px 13.699999809265137px 0px #0000001a;
  width: 11rem;
  min-width: 10rem;
  border-radius: 7px;
  margin-right: 1.2rem;
  min-height: 15.2rem;
  &__icon {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 100%;
    margin: 0 auto 0.6rem;
    display: block;
  }
  &__name {
    font-size: 1.4rem;
    font-family: $font-primary;
    line-height: 2.2rem;
    margin-bottom: 1.8rem;
    color: $color-black;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
  }
  &__pending-btn{
    font-size: 1.2rem;
    color: green;
    text-align: center;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  &__btn {
    background-color: #ec424226;
    color: #ec4242;
    height: 2.8rem;
    font-size: 1.2rem;
    font-family: $font-primary-medium;
    border-radius: 5px !important;
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    padding: 0 !important;
    .anticon {
      background-color: #ffffff85;
      width: 100%;
      border-radius: 5px;
      .anticon-spin {
        width: 1.5rem;
        height: 1.5rem;
        fill: #f71212;
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #ec424226;
      color: #ec4242;
    }
  }
}
</style>
