<template>
    <div class="folder-menu-sidebar">
      <FolderSidebar />
    </div>
</template>

<script>
import FolderSidebar from "./FolderSidebar.vue";
export default {
  components: {
    FolderSidebar,
  },
};
</script>

<style lang="scss">
.folder-menu-sidebar {
  position: fixed;
  top: 8rem;
  background-color: $color-white;
  z-index: 4444;
  width: 100%;
  height: calc(100vh - 57px);
  @include respond(phone-x-small) {
    top: 11rem;
    height: calc(100vh - 78px);
  }
}
</style>
