<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="50rem"
    class="add-new-folder-modal"
  >
    <div class="add-new-folder-modal-container">
      <div class="add-new-folder-modal-container__header">
        <h1>{{ modalTitle }}</h1>
      </div>
      <div class="add-new-folder-modal-container__body">
        <input
          v-model="folderName"
          type="text"
          placeholder="Enter folder name"
          class="add-new-folder-modal-container__body--input"
        />
        <p class="add-new-folder-modal-container__body--error-msg" v-if="error">This field can not be empty</p>
        <!-- <input
        v-model="folderTopic"
        type="text"
        placeholder="Enter Interest Topic"
        class="add-new-folder-modal-container__body--input"
      /> -->
        <a-button :loading="loader" @click="createFolder" class="add-new-folder-modal-container__body--btn">
          {{ button }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    modalTitle: String,
    loader: Boolean,
    action: String,
    folderToBeEdit: Object
  },
  watch: {
    visible(val) {
      if (val) {
        this.error = false
        this.action === 'edit' ? this.folderName = this.folderToBeEdit.name : ""; 
        if (this.action === 'edit') {
          this.folderName = this.folderToBeEdit.name;
          this.folderTopic = this.folderToBeEdit.topic
          this.button = 'Edit Folder'  
        } else {
           this.folderName = ""
        }
      }
    }
  },
  data() {
    return {
      folderName: "",
      folderTopic:"",
      error: false,
      button: 'Add Folder'
    }
  },
  emits: ["close-modal"],
  computed: {},
  methods: {
    closeModal() {
      this.folderName = "";
      this.folderTopic = ""
      this.$emit("close-modal");
    },
    createFolder() {
      if (this.folderName === "") {
          this.error = true
          return false;
      }
    const payload = {
      folderName: this.folderName,
      selectedTopicType: this.folderTopic
    }
      if (this.action === 'edit') {
        this.$emit("edit-folder", payload);
      } else {
        this.$emit("create-folder", payload);
      }
    }
  },
};
</script>

<style lang="scss">
.add-new-folder-modal {
  top: 15rem !important;
  .ant-modal-content {
    background-color: #f6f8ff;

    .ant-modal-close {
      margin: 2.6rem 2.5rem;

      .ant-modal-close-x {
        width: fit-content;
        height: fit-content;
        line-height: 0;
        .anticon {
          color: $color-black;
          svg {
            fill: $color-black;
          }
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      .add-new-folder-modal-container {
        padding: 2.2rem 2.5rem;
        &__header {
          margin-bottom: 2.8rem;
          h1 {
            font-size: 2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2.3rem;
            color: $color-black;
          }
        }
        &__body {
          &--input {
            height: 4rem;
            border: 1px solid $color-dark-grey-5;
            padding: 0 1rem;
            color: $color-black;
            font-size: 1.4rem;
            font-family: $font-primary;
            width: 100%;
            outline: none;
            border-radius: 5px !important;
            margin-bottom: 1.5rem;
            &::placeholder {
              color: $color-black;
              opacity: 0.5;
            }
          }
          &--error-msg {
            margin-top: 1rem;
            font-size: 1.4rem;
            font-family: $font-secondary;
            color: red;
          }
          &--btn {
            margin-top: 1.9rem;
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 3.6rem;
            width: 10.8rem;
            display: block;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
